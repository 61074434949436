import { useNavigate } from 'react-router-dom'

import quickbooksDesktopLogo from '../../assets/quickbooksLogo.png'
import LandingPage from '../../components/landing-page/landing-page'
import { appIconStyle } from '../../style/app-icon-style'

const QuickbooksDesktop = () => {
  const appName = 'QuickBooks Desktop'
  const navigate = useNavigate()
  const handleClick = e => {
    e.preventDefault()
    navigate('/quickbooks-desktop-settings')
  }
  const quickbookDesktopSelections = [
    {
      header: 'What will this application need access to?',
      statements: ['Customers', 'Estimates', 'Invoices', 'Payments']
    },
    {
      header: 'What will this application do?',
      statements: [
        'Keep Contacts, Estimates, Invoices and Payments consolidated in one place.',
        'Streamline your bookkeeping and money management processes.',
        'Stay organized and view in real-time the status of your revenue stream.'
      ]
    }
  ]

  return (
    <LandingPage
      sections={quickbookDesktopSelections}
      onSubmit={handleClick}
      appName={appName}
      appIconSource={quickbooksDesktopLogo}
      appIconStyle={appIconStyle}
    />
  )
}

export default QuickbooksDesktop
