import { useNavigate } from 'react-router-dom'

import myobLogo from '../../assets/myobLogo.png'
import LandingPage from '../../components/landing-page/landing-page'
import { appIconStyle } from '../../style/app-icon-style'

const MYOB = () => {
  const appName = 'MYOB Accounting'
  const navigate = useNavigate()
  const handleClick = e => {
    e.preventDefault()
    navigate('/myob-accounting-settings')
  }
  const myobSelections = [
    {
      header: 'What will this application do?',
      statements: [
        'Keep Contacts, Estimates, Invoices, and Payments consolidated in one place.',
        'Streamline your bookkeeping and money managment processes.',
        'Stay organised and view in real-time the status of your revenue stream.'
      ]
    }
  ]

  return (
    <LandingPage
      sections={myobSelections}
      onSubmit={handleClick}
      appName={appName}
      appIconSource={myobLogo}
      appIconStyle={appIconStyle}
    />
  )
}

export default MYOB
